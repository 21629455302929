import * as React from 'react'
import { Card, CardContent, Container, InputAdornment, Stack, Typography } from '@mui/material'
import FullWidthTextField from '../../compoments/Forms/FullWidthTextField'
import AccountCircle from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import { getFullDeviceInfo } from '../../utils/deviceInfo'
import login from '../../axios/auth/login';
import { LoadingButton } from '@mui/lab'
import AuthAtom from '../../atoms/AuthAtom';
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';

function LoginPage() {

    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [auth, setAuth] = useRecoilState(AuthAtom)
    const [authCookie, setAuthCookie] = useCookies(['auth'])
    const navigate = useNavigate()

    React.useEffect(() => {
        if (authCookie && authCookie.data)
            setAuth(authCookie.data)
    }, [])

    React.useEffect(() => {
        if (auth)
            navigate('/dashboard')
    }, [auth, setAuth])

    const loginClicked = (e) => {
        e.preventDefault()
        setLoading(true)
        let data = {
            username: username,
            password: password,
            token_name: getFullDeviceInfo(),
        }
        login(data, (res) => {
            let authData = { ...res.data.user, token: res.data.token }
            setAuthCookie('data', JSON.stringify(authData))
            setAuth(authData)
            // navigate('/dashboard')
        }, (err) => {
            setError(err.data.message)
            setLoading(false)
        })
    }

    return (
        <Container maxWidth='sm' sx={{ mt: { xs: '30%', md: '5%' } }}>
            <Card sx={{ px: 4 }}>
                <CardContent>
                    <form onSubmit={loginClicked}>
                        <Stack>
                            <Typography
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                                variant='h5'
                                my={4}>
                                {'ورود کتاب‌فروشی‌ها'}
                            </Typography>
                            <Typography mb={2}>
                                {'نام کاربری'}
                            </Typography>
                            <FullWidthTextField
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                sx={{ mb: 4 }}
                                variant='outlined'
                                placeholder='نام کاربری'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography mb={2}>
                                {'رمز عبور'}
                            </Typography>
                            <FullWidthTextField
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{ mb: 4 }}
                                variant='outlined'
                                type='password'
                                placeholder='رمز عبور'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography color={'error'} sx={{ mb: 4 }}>
                                {error}
                            </Typography>
                            <LoadingButton
                                loading={loading}
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mb: 2 }}>
                                {'ورود'}
                            </LoadingButton>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Container >
    )
}

export default LoginPage;