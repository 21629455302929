import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";
import axios from "axios";

export default function login(data, onSuccess, onFailure) {

    let options = {
        method: 'post',
        url: links.auth.login,
        data: data,
    }
    axios(options).then(
        (res) => {
            onSuccess(res.data)
        },
        (err) => {
            onFailure(err.response)
        }
    )
}