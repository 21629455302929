import * as React from 'react'
import { Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material'

function DashboardCard({ header, body, footer, onClick, sx }) {
    return (
        <Card variant='elevation' onClick={onClick} sx={{...sx}}>
            <CardActionArea>
                <CardContent>
                    <Stack direction={'column'} spacing={2}>
                        <Typography variant='h6' textAlign='right'>
                            {header}
                        </Typography>
                        <Typography variant='h4' textAlign='center'>
                            {body}
                        </Typography>
                        <Typography variant='h6' textAlign='left'>
                            {footer}
                        </Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default DashboardCard;