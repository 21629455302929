import * as React from 'react'
import {CircularProgress, IconButton, InputAdornment, OutlinedInput} from '@mui/material'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

function IsbnInput({value, setValue, openDialog, loading, onBlur}) {
    return (
        <OutlinedInput
            fullWidth
            placeholder='شابک'
            onBlur={onBlur}
            type='number'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            endAdornment={
                <InputAdornment position="end">
                    {loading ? <CircularProgress /> :
                        <IconButton
                            aria-label="اسکن بارکد"
                            onClick={openDialog}
                            edge="end"
                        >
                            <QrCodeScannerIcon />
                        </IconButton>}
                </InputAdornment>
            } />
    )
}

export default IsbnInput;