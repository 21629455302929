import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';
import getAllInvoices from "../../axios/invoices/getAllInvoices"
import { Paper, Stack, Pagination, TextField, Typography, Button, Grid, Container } from '@mui/material'
import Sale from '../../compoments/Sales/Sale'
import AdapterJalali from '@date-io/date-fns-jalali';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { formatDate } from '../../utils/date';
import { useSearchParams } from 'react-router-dom'
import colors from '../../Theme/colors';



function SalesPage() {

    const [searchParams, setSearchParams] = useSearchParams()
    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    const [invoices, setInvoices] = React.useState([])
    const [pageCount, setPageCount] = React.useState(1)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'sales', appbarTitle: translate('sales') })
        if (!searchParams.get('page'))
            setSearchParams({ page: 1 })
        fetchPage(1)
    }, [])
    const [selectedDates, setSelectedDates] = React.useState({
        from: null,
        to: null,
    })

    const fetchPage = (number, from = '', to = '') => {
        setSearchParams({ page: number })
        getAllInvoices({ from: from, to: to, page: number }, (res) => {
            setInvoices(res.data.invoices)
            setPageCount(res.data.last_page)
        }, (e) => { console.log(e) })
    }

    const filterClicked = () => {
        let from = ''
        let to = ''
        if (selectedDates.from)
            from = formatDate(selectedDates.from)
        if (selectedDates.to)
            to = formatDate(selectedDates.to)
        fetchPage(1, from, to)
    }



    return (
        <Container disableGutters>
            <Paper sx={{ p: 4 }}>
                <Grid container spacing={4} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={3}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant='h6'>
                                {'تاریخ شروع'}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DatePicker
                                    clearable
                                    maxDate={selectedDates.to}
                                    mask="____/__/__"
                                    value={selectedDates.from}
                                    onChange={(newValue) => setSelectedDates({ ...selectedDates, from: newValue })}
                                    renderInput={(params) => <TextField {...params} placeholder={'تاریخ شروع'} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant='h6'>
                                {'تاریخ پایان'}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DatePicker
                                    clearable
                                    minDate={selectedDates.from}
                                    mask="____/__/__"
                                    value={selectedDates.to}
                                    onChange={(newValue) => setSelectedDates({ ...selectedDates, to: newValue })}
                                    renderInput={(params) => <TextField {...params} placeholder={'تاریخ پایان'} />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1} alignSelf={'end'}>
                        <Button variant='contained' fullWidth sx={{ mb: 1.5 }} onClick={filterClicked}>
                            {'اعمال'}
                        </Button>
                    </Grid>
                </Grid>
                <div>
                    {invoices.map((item, index) =>
                        <Sale key={index} {...item}  />
                    )}
                </div>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item justifyContent={'center'} alignItems={'center'}>
                        {pageCount > 1 && <Pagination
                            sx={{ mt: 4 }}
                            count={pageCount}
                            page={parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1}
                            onChange={(e, v) => fetchPage(v)} />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default SalesPage;