import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';

function OrdersPage() {

    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({...env, sidebarSelectedItem: 'orders', appbarTitle: translate('orders')})
    }, [])

    return (
        <div>
            This is orders page!
        </div>
    )
}

export default OrdersPage;