import * as React from 'react'
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import {useRecoilState} from 'recoil'
import SimpleListItem from './SimpleListItem';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function ListItemWithChild({ item, onToggle, open, onItemClicked }) {

    return (
        <React.Fragment>
            <ListItem button onClick={() => onToggle()}>
                <ListItemIcon>
                    {item.icon}
                </ListItemIcon>
                <ListItemText sx={{ textAlign: 'right' }}>
                    {item.label}
                </ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open}>
                <List>
                    {item.children.map((child, index) =>
                        <SimpleListItem key={index} item={child} onItemClicked={onItemClicked} sx={{pr: 4}} />
                    )}
                </List>
            </Collapse>
        </React.Fragment>
    )
}

export default ListItemWithChild;