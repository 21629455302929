// const domain = 'http://localhost:8000/api/'
const domain = 'https://taha7900.ir/api.booklet/api/'

const links = {
    auth: {
        login: domain + 'auth/login',
        logout: domain + "auth/logout",
        changePassword: domain + 'auth/change-password',
        edit: domain + 'auth/edit',
        registerRequest: domain + "auth/register-request",
    },
    book: {
        search: domain + 'book/search',
        create: domain + 'book/create',
    },
    invoice: {
        create: domain + 'invoice/create',
        getAll: domain + 'invoice/get-all',
    },
    bookStore: {
        getBook: domain + "bookstore/get-book",
        getSummary: domain + "bookstore",
        addBook: domain + "bookstore/add-book",
        getBooks: domain + "bookstore/books",
        edit: domain + "bookstore/edit",
        bookCount: domain + "bookstore/book-count",
    },
    ticket: {
        ticket: domain + "ticket",
        getAll: domain + "ticket/all",
        addMessage: domain + "ticket/message",
        close: domain + "ticket/close",
    }
}

export default links