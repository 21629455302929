const dictionary = {
    fa: {
        'میزکار': 'dashboard',
        'افزودن کتاب': 'addBook',
        'کتاب‌ها': 'books',
        'سفارشات': 'orders',
        'سابقه فروش': 'sales',
        'فروش جدید': 'addSale',
        'تنظیمات': 'settings',
        'تیکت‌ها': 'tickets',
        'افزودن تیکت': 'addTicket',
        'در انتظار پاسخ': 'waiting response',
        'پاسخ داده شده': 'answered',
        'پایان یافته': 'closed',
        'پرسش': 'question',
        'پاسخ': 'response',
    },

    en: {
        'dashboard': 'میزکار',
        'addBook': 'افزودن کتاب',
        'books': 'کتاب‌ها',
        'orders': 'سفارشات',
        'sales': 'سابقه فروش',
        'addSale': 'فروش جدید',
        'settings': 'تنظیمات',
        'tickets': 'تیکت‌ها',
        'addTicket': 'افزودن تیکت',
        'waiting response': 'در انتظار پاسخ',
        'answered': 'پاسخ داده شده',
        'closed': 'پایان یافته',
        'question': 'پرسش',
        'response': 'پاسخ'
    }
}

const translate = (word, src = 'en') => {
    return dictionary[src][word]
}

export default translate;