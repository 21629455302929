import * as React from 'react';
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';
import DashboardCard from '../../compoments/Dashboard/DashboardCard';
import { Divider, Grid, Stack, Typography, Paper, Link, Button, Container } from '@mui/material';
import Book from '../../compoments/Books/Book';
import Sale from '../../compoments/Sales/Sale';
import colors from '../../Theme/colors';
import getSummary from "../../axios/bookstore/getSummary"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const processServerData = (response, setDashboardData) => {
    let recent_sales = response.data.recent_sales;
    let recent_books = response.data.recent_books;
    let recent_orders = response.data.recent_orders //TODO: later

    let lastMonth = new Date()
    lastMonth.setTime(new Date().getTime() - 30 * 24 * 3600 * 1000)
    let monthly_income = recent_sales.reduce((prev, curr) => {
        let createdAt = new Date(curr.created_at)
        if (createdAt.getTime() > lastMonth.getTime())
            prev += curr.books.reduce((totalPrice, curBook) => totalPrice + curBook.total_price, 0)
        return prev
    }, 0)

    let lastWeek = new Date()
    lastWeek.setTime(new Date().getTime() - 7 * 24 * 3600 * 1000)
    let weekly_income = recent_sales.reduce((prev, curr) => {
        let createdAt = new Date(curr.created_at)
        if (createdAt.getTime() > lastWeek.getTime())
            prev += curr.books.reduce((totalPrice, curBook) => totalPrice + curBook.total_price, 0)
        return prev
    }, 0)

    let recentBooks = recent_books.map(item => {
        return {
            ...item,
            isBookStore: true,
        }
    })

    setDashboardData({
        weekly_income: weekly_income,
        monthly_income: monthly_income,
        recent_books: recentBooks,
        recent_orders: recent_orders,
        recent_sales: recent_sales.slice(0, 5),
        sale_data: recent_sales.reverse(),
    })
}

function DashboardPage() {

    // const cardBackground = `${colors.primary[2]}15`

    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'dashboard', appbarTitle: translate('dashboard') })
    }, [])

    const [dashboardData, setDashboardData] = React.useState({
        weekly_income: 0,
        monthly_income: 0,
        recent_orders: [],
        recent_books: [],
        recent_sales: [],
    })

    const cards = [
        {
            header: 'فروش هفتگی',
            body: dashboardData.weekly_income,
            footer: 'تومان',
            xs: 12,
            md: 4,
        },
        {
            header: 'فروش ماهانه',
            body: dashboardData.monthly_income,
            footer: 'تومان',
            xs: 12,
            md: 4,
        },
        {
            header: 'سفارشات جدید',
            body: dashboardData.recent_orders.length,
            footer: 'عدد',
            xs: 12,
            md: 4,
        },
    ]

    React.useEffect(() => {
        getSummary((res) => {
            processServerData(res, setDashboardData)
        }, () => { })
    }, [])

    return (
        <Container disableGutters>
            <Stack direction={'column'} spacing={2}>
                <>
                    <Grid container spacing={4}>
                        {cards.map((card, index) =>
                            <Grid key={index} item xs={card.xs} md={card.md}>
                                <DashboardCard
                                    header={card.header}
                                    body={card.body}
                                    footer={card.footer}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
                <>
                    <Paper sx={{ p: 4 }}>
                        <Stack direction={'row'} justifyContent='space-between'>
                            <Typography variant='h6' mb={4}>
                                {'آخرین فروش‌ها'}
                            </Typography>
                            <Link href='/sales-history' underline='none'>
                                <Button dir='ltr' startIcon={<ArrowBackIosIcon />} >
                                    {'همه‌ی فروش‌ها'}
                                </Button>
                            </Link>
                        </Stack>
                        <div>
                            {dashboardData.recent_sales.map((sale, index) =>
                                <Sale {...sale} id={index + 1} key={index} />
                            )}
                        </div>
                    </Paper>
                </>
                <>
                    <Paper sx={{ p: 4 }}>
                        <Stack direction={'row'} justifyContent='space-between'>
                            <Typography variant='h6' mb={4}>
                                {'سفارشات جدید'}
                            </Typography>
                            <Link href='/orders' underline='none'>
                                <Button dir='ltr' startIcon={<ArrowBackIosIcon />} >
                                    {'همه‌ی سفارشات'}
                                </Button>
                            </Link>
                        </Stack>
                        <Grid container spacing={4} justifyContent={'center'} alignItems={'center'} mb={4}>
                            {'سفارش جدیدی وجود ندارد'}
                        </Grid>
                    </Paper>
                </>
                <>
                    <Paper sx={{ p: 4 }}>
                        <Stack direction={'row'} justifyContent='space-between'>
                            <Typography variant='h6' mb={4}>
                                {'آخرین کتاب‌ها'}
                            </Typography>
                            <Link href='/books' underline='none'>
                                <Button dir='ltr' startIcon={<ArrowBackIosIcon />} >
                                    {'همه‌ی کتاب‌ها'}
                                </Button>
                            </Link>
                        </Stack>
                        <Grid container spacing={4} mb={4}>
                            {dashboardData.recent_books.map((book, index) =>
                                <Grid item xs={12} md={3} key={index}>
                                    <Book {...book} />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </>
            </Stack >
        </Container>
    )
}

export default DashboardPage;