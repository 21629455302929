import * as React from 'react';
import { AppBar, Box, Toolbar, Typography, Container, Button } from '@mui/material';
import Logo from '../resources/Booklet-Logo.png'
import colors from '../Theme/colors';
import { useNavigate } from 'react-router-dom'

const pages = [
    {
        name: 'ورود',
        url: '/login',
        newTab: false,
    },
    {
        name: 'ثبت نام',
        url: '/register',
        newTab: false,
    },
    {
        name: 'درباره ما',
        url: '',
        newTab: false,
    },
];

function TopAppBar() {

    const navigate = useNavigate()

    return (
        <AppBar
            position="absolute"
            sx={{
                boxShadow: 'none',
                py: { xs: 0, lg: 3 },
                background: { xs: `${colors.secondary[1]}af`, lg: 'transparent' }
            }}>
            <Container maxWidth="xl">
                <Toolbar>
                    <img
                        src={Logo}
                        style={{
                            display: 'inline-block',
                            width: '50px',
                            height: '50px',
                            borderRadius: '8px'
                        }}
                        alt={'عکس پروفایل'}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        color={colors.primary[8]}
                        component="div"
                        sx={{ mr: 2, ml: 4, display: { xs: 'none', md: 'flex' } }}
                    >
                        {'بوک‌لت'}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        {pages.map((page) => (
                            <Button
                                onClick={() => {
                                    page.newTab ? window.open(page.url, '_blank').focus() : navigate(page.url)
                                }}
                                variant='text'
                                key={page.name}
                                sx={{ my: 2, mx: 4, display: 'block', color: colors.primary[8] }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopAppBar;