import * as React from 'react'
import { useParams } from 'react-router-dom'
import getTicketMessages from '../../axios/tickets/getTicketMessages'
import { Collapse, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import TicketMessage from '../../compoments/Tickets/TicketMessage'
import FullWidthTextField from '../../compoments/Forms/FullWidthTextField'
import { LoadingButton } from '@mui/lab'
import ExpandMore from '../../compoments/Utils/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import addMessage from '../../axios/tickets/addMessage'


function TicketDetailsPage() {
    const { id } = useParams()
    const [messages, setMessages] = React.useState([])
    const [expanded, setExpanded] = React.useState(false)
    const [response, setResponse] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    React.useState(() => {
        getTicketMessages(id, (res) => {
            setMessages(res.data.messages)
        }, (err) => {
            console.log(err)
        })
    }, [])

    const responseSubmited = () => {
        if (!response)
            return;
        setLoading(true)
        let data = {
            ticket_id: id,
            text: response,
        }
        addMessage(data, (res) => {
            let newMessage = res.data.message;
            setMessages([newMessage, ...messages])
            setLoading(false)
            setExpanded(false)
            setResponse('')
        }, (err) => {
            console.log(err)
            setLoading(false)
        })
    }
    return (
        <Container>
            <Paper sx={{ p: 4, mb: 2 }}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{cursor: 'pointer'}}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Typography variant='h6'>
                        {'افزودن پاسخ'}
                    </Typography>
                    <ExpandMore expand={expanded}>
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Stack>
                <Collapse in={expanded}>
                    <FullWidthTextField
                        placeholder='پیام'
                        multiline
                        rows={8}
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                        sx={{ mb: 4, mt: 2 }}
                    />
                    <Grid container justifyContent={'left'}>
                        <Grid item xs={12} md={2} lg={1.5}>
                            <LoadingButton 
                                onClick={responseSubmited}
                                fullWidth
                                loading={loading}
                                variant={'contained'}>
                                {'ثبت'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>
            <Paper sx={{ p: 4 }}>
                {messages.map((message, index) =>
                    <TicketMessage {...message} key={index} />
                )}
            </Paper>
        </Container>
    )
}

export default TicketDetailsPage;