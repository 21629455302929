import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';
import { Container, Grid, Paper, Typography } from '@mui/material'
import FormInput from '../../compoments/Forms/FormInput';
import AuthAtom from '../../atoms/AuthAtom'
import { LoadingButton } from '@mui/lab'
import editPersonalData from '../../axios/auth/editPersonalData'
import changePassword from '../../axios/auth/changePassword'
import editBookStoreData from '../../axios/bookstore/editBookStoreData'
import { useCookies } from 'react-cookie';
import UserInfoForm from '../../compoments/Forms/Complete/UserInfoForm';
import BookStoreInfoForm from '../../compoments/Forms/Complete/BookStoreInfoForm';

function SettingsPage() {

    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'settings', appbarTitle: translate('settings') })
    }, [])
    const [auth, setAuth] = useRecoilState(AuthAtom)
    const [authCookie, setAuthCookie] = useCookies(['auth'])

    React.useEffect(() => {
        setAuthCookie('data', JSON.stringify(auth))
    }, [auth, setAuth])


    const defaultPersoanlData = {
        username: auth.username,
        name: auth.name,
        surname: auth.surname,
        phone_number: auth.phone_number,
        email: auth.email,
    }

    const defaultBookStoreData = {
        username: auth.book_stores[0].username,
        name: auth.book_stores[0].name,
        address: auth.book_stores[0].address,
        phone_number: auth.book_stores[0].phone_number,
    }

    const defaultPasswordData = {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    }

    const [personalData, setPersoanlData] = React.useState(defaultPersoanlData)
    const [bookStoreData, setBookStoreData] = React.useState(defaultBookStoreData);
    const [passwordData, setPasswordData] = React.useState(defaultPasswordData)
    const [passwordError, setPasswordError] = React.useState('')
    const [loading, setLoading] = React.useState([]) //persoanl, password, bookStore

    const setPersoanlDataKey = (key, value) => {
        setPersoanlData({...personalData, [key]: value});
    }

    const setBookStoreDataKey = (key, value) => {
        setBookStoreData({...bookStoreData, [key]: value})
    }

    const setPasswordDataKey = (key, value) => {
        setPasswordData({ ...passwordData, [key]: value })
    }

    const submitPersonalData = () => {
        setLoading([...loading, 'personal'])
        editPersonalData(personalData, (res) => {
            setLoading(loading.filter(item => item !== 'personal'))
            setAuth({
                ...auth,
                name: personalData.name,
                surname: personalData.surname,
                email: personalData.email
            })
        }, (err) => {
            console.log(err)
        })
    }

    const submitNewPassword = () => {
        setLoading([...loading, 'password'])
        changePassword(passwordData, (res) => {
            setLoading(loading.filter(item => item !== 'password'))
        }, (err) => {
            setLoading(loading.filter(item => item !== 'password'))
            setPasswordError(err.data.message)
        })
    }

    const submitBookStoreData = () => {
        setLoading([...loading, 'bookStore'])
        editBookStoreData(bookStoreData, (res) => {
            setLoading(loading.filter(item => item !== 'bookStore'))
            setAuth({
                ...auth,
                book_stores: [
                    {
                        ...auth.book_stores[0],
                        name: bookStoreData.name,
                        address: bookStoreData.address,
                        phone_number: bookStoreData.phone_number,
                    }
                ]
            })
        }, (err) => {
            console.log(err)
        })
    }

    return (
        <Container disableGutters>
            <UserInfoForm
                data={personalData}
                setDataKey={setPersoanlDataKey}
                loading={loading.includes('personal')}
                submitLabel={'ثبت تغییرات'}
                onSubmit={submitPersonalData}
            />
            <Paper sx={{ p: 4, mb: 4 }}>
                <Typography variant='h4' mb={4}>
                    {'تغییر رمز عبور'}
                </Typography>
                <Grid container spacing={2} mb={4}>
                    <FormInput
                        label={'رمز عبور قدیمی'}
                        value={passwordData.old_password}
                        setValue={(v) => setPasswordDataKey('old_password', v)}
                        type={'password'}
                        xs={12}
                        md={4} />
                    <FormInput
                        label={'رمز عبور جدید'}
                        value={passwordData.new_password}
                        type={'password'}
                        setValue={(v) => setPasswordDataKey('new_password', v)}
                        xs={12}
                        md={4} />
                    <FormInput
                        label={'تکرار رمز عبور جدید'}
                        value={passwordData.new_password_confirmation}
                        type={'password'}
                        setValue={(v) => setPasswordDataKey('new_password_confirmation', v)}
                        xs={12}
                        md={4} />
                </Grid>
                <Grid container justifyContent={'left'} alignItems={'center'} spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography color='error' textAlign={'left'}>
                            {passwordError}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1.5}>
                        <LoadingButton
                            fullWidth
                            onClick={submitNewPassword}
                            loading={loading.includes('password')}
                            variant='contained'
                        >
                            {'تغییر رمز عبور'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
            <BookStoreInfoForm
                data={bookStoreData}
                setDataKey={setBookStoreDataKey}
                loading={loading.includes('bookStore')}
                submitLabel={'ثبت تغییرات'}
                onSubmit={submitBookStoreData}
            />
            {/* <Paper sx={{ p: 4 }}>
                <Typography variant='h4' mb={4}>
                    {'حریم خصوصی و امنیت'}
                </Typography>
            </Paper> */}
        </Container>
    )
}

export default SettingsPage;