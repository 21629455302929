import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function editPersonalData(data, onSuccess, onFailure) {

    let options = {
        method: 'post',
        url: links.auth.edit,
        data: data,
    }
    authorizedAxios(options, onSuccess, onFailure)

}