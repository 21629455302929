import * as React from 'react'
function LoadingPage() {

    // TODO: complete
    return (
        <div>
            <h3>Loading...</h3>
        </div>
    )
}

export default LoadingPage;