import * as React from 'react'
import { Card, CardContent, Container, Grid, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import FormInput from '../../compoments/Forms/FormInput';
import FullWidthTextField from '../../compoments/Forms/FullWidthTextField';
import cities from '../../data/cities'
import registerRequest from '../../axios/auth/registerRequest'

function RegisterPage() {

    const defaultData = {
        name: '',
        surname: '',
        phone_number: '',
        book_store_name: '',
        province: '',
        city: '',
        address: '',
    }
    const [data, setData] = React.useState(defaultData)
    const [message, setMessage] = React.useState({
        text: '',
        color: 'error',
    })
    const [loading, setLoading] = React.useState(false)
    const [availableCities, setAvailableCities] = React.useState([])

    React.useEffect(() => {
        if (data.province !== '')
            setAvailableCities(cities[parseInt(data.province) - 1].cities)
    }, [data.province])

    const setDataKey = (key, value) => {
        setData({ ...data, [key]: value })
    }

    const checkData = () => {
        return Object.entries(data).every(([_, v]) => v != null && v !== '');
    }

    const submitClicked = () => {
        if (!checkData()) {
            setMessage({
                text: 'لطفا تمامی اطلاعات را پر کنید',
                color: 'error',
            })
            return
        }
        setLoading(true)
        setMessage({ text: '', color: 'error' })
        registerRequest(
            data,
            (res) => {
                setLoading(false)
                setData(defaultData)
                setMessage({
                    text: 'اطلاعات شما با موفقیت ذخیره شد',
                    color: 'success',
                })
            },
            (err) => {
                setLoading(false)
                setMessage({
                    text: err.data.message,
                    color: 'error',
                })
            })
    }

    return (
        <Container sx={{ mt: { xs: '30%', md: '5%' } }}>
            <Card sx={{ px: 4 }}>
                <CardContent>
                    <form onSubmit={() => { }}>
                        <Stack>
                            <Typography
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                                variant='h5'
                                my={4}>
                                {'ثبت‌نام کتاب‌فروشی‌ها'}
                            </Typography>
                            <Typography variant='body' sx={{ whiteSpace: 'pre-line' }} mb={4}>
                                {
                                    'از اینکه تمایل دارید کتاب‌فروشی خود را در بوک‌لت ثبت کنید سپاس‌گزاریم!' + "\n" +
                                    "برای ثبت نام در بوک‌لت، لطفا فرم زیر را تکمیل کنید. پشتیبانی ما در اسرع وقت با شما تماس خواهد گرفت."
                                }
                            </Typography>
                            <Grid container spacing={2} mb={2}>
                                <FormInput
                                    label={'نام'}
                                    type={'text'}
                                    value={data.name}
                                    setValue={(v) => setDataKey('name', v)}
                                    xs={12}
                                    md={6} />
                                <FormInput
                                    label={'نام خانوادگی'}
                                    type={'text'}
                                    value={data.surname}
                                    setValue={(v) => setDataKey('surname', v)}
                                    xs={12}
                                    md={6} />
                            </Grid>
                            <Grid container spacing={2} mb={6}>
                                <FormInput
                                    label={'نام کتاب‌فروشی'}
                                    type={'text'}
                                    value={data.book_store_name}
                                    setValue={(v) => setDataKey('book_store_name', v)}
                                    xs={12}
                                    md={8} />
                                <FormInput
                                    label={'شماره تماس'}
                                    type={'number'}
                                    value={data.phone_number}
                                    setValue={(v) => setDataKey('phone_number', v)}
                                    xs={12}
                                    md={4} />
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12} md={4}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Typography variant='h6'>
                                            {"استان"}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            placeholder={"استان"}
                                            select
                                            value={data.province}
                                            onChange={(e) => setDataKey("province", e.target.value)}>
                                            {cities.map((province) =>
                                                <MenuItem key={province.id} value={province.id}>
                                                    {province.name}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Stack direction={'column'} spacing={2}>
                                        <Typography variant='h6'>
                                            {"شهرستان"}
                                        </Typography>
                                        <FullWidthTextField
                                            placeholder={"شهرستان"}
                                            select
                                            disabled={data.province === ''}
                                            value={data.city}
                                            onChange={(e) => setDataKey("city", e.target.value)}
                                        >
                                            {availableCities.map(city =>
                                                <MenuItem key={city.id} value={city.id}>
                                                    {city.name}
                                                </MenuItem>
                                            )}
                                        </FullWidthTextField>
                                    </Stack>
                                </Grid>
                                <FormInput
                                    label={'آدرس'}
                                    type={'text'}
                                    value={data.address}
                                    setValue={(v) => setDataKey('address', v)}
                                    rows={4}
                                    xs={12}
                                    md={12} />
                            </Grid>
                            <Grid container spacing={4} mb={2} justifyContent={'left'}>
                                <Grid item xs={12} md={4} sx={{my: 'auto'}}>
                                    <Typography color={message.color} textAlign={'left'}>
                                        {message.text}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <LoadingButton
                                        loading={loading}
                                        variant={'contained'}
                                        fullWidth
                                        onClick={submitClicked}
                                    >
                                        {"ثبت اطلاعات"}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Container >
    )
}

export default RegisterPage;