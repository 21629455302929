import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function createInvoice(data, onSuccess, onFailure) {

    let filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));

    let options = {
        method: 'post',
        url: links.invoice.create,
        data: filteredData,
    }
    authorizedAxios(options, onSuccess, onFailure);
}