import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function logout(onSuccess, onFailure) {

    let options = {
        method: 'delete',
        url: links.auth.logout,
    }
    authorizedAxios(options, onSuccess, onFailure)
}