import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";
import axios from "axios";

export default function searchBook(data, onSuccess, onFailure) {

    let filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));

    let options = {
        method: 'get',
        url: links.book.search,
        params: filteredData,
    }
    axios(options)
        .then((res) => {
            onSuccess(res.data)
        }, (err) => {
            onFailure(err.response)
        });
}