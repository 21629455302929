import * as React from 'react'
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import {useRecoilState} from 'recoil'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

function SimpleListItem({ item, onItemClicked,  sx}) {

    let [env] = useRecoilState(EnvironmentAtom)

    return (
        <ListItem button onClick={() => onItemClicked(item.url)} selected={env.sidebarSelectedItem == item.key} sx={sx} disabled={item.disabled}>
            <ListItemIcon>
                {item.icon}
            </ListItemIcon>
            <ListItemText sx={{ textAlign: 'right' }}>
                {item.label}
            </ListItemText>
        </ListItem>
    )
}

export default SimpleListItem;