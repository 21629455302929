import * as React from 'react'
import { useRecoilState } from 'recoil'
import EnvironmentAtom from '../../atoms/EnvironmentAtom'
import translate from '../../utils/translate'
import { Button, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import BarcodeDialog from '../../compoments/Books/BarcodeDialog';
import searchBook from '../../axios/books/searchBook'
import FullWidthTextField from "../../compoments/Forms/FullWidthTextField"
import IsbnInput from '../../compoments/Books/IsbnInput'
import addBookToBookStore from '../../axios/books/addBookToBookStore'
import createBook from '../../axios/books/createBook'

const bookFields = [
    {
        groupName: 'نام کتاب و پدیدآور',
        children: [
            {
                placeholder: 'نام کتاب',
                valueName: 'name',
                inputType: 'text',
                md: 7,
            },
            {
                placeholder: 'نویسنده',
                valueName: 'writer',
                inputType: 'text',
                md: 5,
            },
        ]
    },
    {
        groupName: 'اطلاعات نشر',
        children: [
            {
                placeholder: 'انتشارات',
                valueName: 'publication',
                inputType: 'text',
                md: 4
            },
            {
                placeholder: 'سال چاپ',
                valueName: 'publish_year',
                inputType: 'number',
                md: 2,
            }
        ]
    },
]

const formFields = [
    {
        placeholder: 'تعداد',
        valueName: 'count',
        inputType: 'number',
        md: 4,
        rows: 1,
    },
    {
        placeholder: 'قیمت',
        valueName: 'price',
        inputType: 'number',
        md: 4,
        rows: 1,
    },
    {
        placeholder: 'اطلاعات',
        valueName: 'info',
        inputType: 'text',
        md: 12,
        rows: 4,
    },
    {
        placeholder: 'لینک تصویر',
        valueName: 'image_url',
        inputType: 'text',
        rows: 1,
        md: 4,
    }
]

function AddBooksPage() {

    const [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'addBook', appbarTitle: translate('addBook') })
    }, [])
    const [error, setError] = React.useState('')
    const [pageStatus, setPageStatus] = React.useState({
        dialogOpen: false,
        loading: false,
        barcodeLoading: false,
        bookFound: false,
    })

    const defaultBookData = {
        id: '',
        isbn: '',
        name: '',
        writer: '',
        publication: '',
        publish_year: '',
    }

    const defaultformData = {
        count: '',
        price: '',
        info: '',
        image_url: '',
    }
    const [bookData, setBookData] = React.useState(defaultBookData)
    const [formData, setFormData] = React.useState(defaultformData)

    const setPageStatusItem = (key, value) => {
        setPageStatus({ ...pageStatus, [key]: value })
    }

    const setBookDataItem = (key, value) => {
        setBookData({ ...bookData, [key]: value })
    }

    const barcodeDetected = (result) => {
        setBookDataItem('isbn', result.text)
        setPageStatus({ ...pageStatus, dialogOpen: false, barcodeLoading: true })
    }

    const sendSearchRequest = () => {
        searchBook(bookData, (result) => {
            setBookData(result.data.book)
            setPageStatus({ ...pageStatus, dialogOpen: false, barcodeLoading: false, bookFound: true })
        }, (err) => {
            setPageStatus({ ...pageStatus, dialogOpen: false, barcodeLoading: false, bookFound: false })
        })
    }

    const isbnLostFocus = (event) => {
        if (event.target.value && event.target.value != '')
            setPageStatus({ ...pageStatus, dialogOpen: false, barcodeLoading: true })
        else {
            setBookData(defaultBookData)
            setPageStatus({ ...pageStatus, bookFound: false })
        }
    }

    React.useEffect(() => {
        if (pageStatus.barcodeLoading)
            sendSearchRequest()
    }, [pageStatus.barcodeLoading])

    const checkFields = () => {
        if (!pageStatus.bookFound) {
            if (!bookData.isbn) {
                setError('مقدار شابک نباید خالی باشد')
                return false
            } else if (!bookData.name) {
                setError('مقدار نام کتاب نباید خالی باشد')
                return false
            } else if (!bookData.publication) {
                setError('مقدارانتشارات نباید خالی باشد')
                return false
            } else if (!bookData.publish_year) {
                setError('مقدار سال انتشار نباید خالی باشد')
                return false
            } else if (!bookData.writer) {
                setError('مقدار نویسنده نباید خالی باشد')
                return false
            }
        }
        if (!formData.count) {
            setError('لطفا تعداد کتاب را تعیین کنید')
            return false;
        } else if (!formData.price) {
            setError('لطفا قیمت را تعیین کنید')
            return false;
        }
        return true;
    }

    const onSubmitClicked = () => {
        if (!checkFields()) {
            setTimeout(() => setError(''), 4000)
            return;
        }
        if (pageStatus.bookFound) {
            addBookToBookStore({ ...formData, book_id: bookData.id },
                (res) => {
                    setBookData(defaultBookData)
                    setFormData(defaultformData)
                    setError('')
                }, (err) => {
                    setError(err.data.message)
                })
        } else {
            createBook({ ...formData, ...bookData },
                (res) => {
                    setBookData(defaultBookData)
                    setFormData(defaultformData)
                    setError('')
                }, (err) => {
                    console.log(err.data.message)
                })
        }
    }

    return (
        <>
            <BarcodeDialog
                open={pageStatus.dialogOpen}
                onClose={() => setPageStatusItem('dialogOpen', false)}
                onResult={barcodeDetected}
            />
            <Container disableGutters>
                <Paper sx={{ p: 4 }}>
                    <Stack direction={'column'}>
                        <Typography variant='h6' mb={2}>
                            {'شابک/شاپا'}
                        </Typography>
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={12} md={4}>
                                <IsbnInput
                                    value={bookData.isbn}
                                    onBlur={isbnLostFocus}
                                    loading={pageStatus.barcodeLoading}
                                    openDialog={() => setPageStatusItem('dialogOpen', true)}
                                    setValue={(v) => setBookDataItem('isbn', v)} />
                            </Grid>
                        </Grid>
                        {bookFields.map((group, index) =>
                            <React.Fragment key={index}>
                                <Typography variant='h6' mb={2}>
                                    {group.groupName}
                                </Typography>

                                <Grid container spacing={2} mb={4}>
                                    {group.children.map((field, index) =>
                                        <Grid item xs={12} md={field.md} key={index}>
                                            <FullWidthTextField
                                                type={field.inputType}
                                                placeholder={field.placeholder}
                                                disabled={pageStatus.barcodeLoading || pageStatus.bookFound}
                                                value={bookData[field.valueName]}
                                                onChange={e => setBookDataItem(field.valueName, e.target.value)} />
                                        </Grid>
                                    )}
                                </Grid>
                                <Divider sx={{ marginBottom: 4 }} />
                            </React.Fragment>
                        )}
                        <Typography variant='h6' mb={2}>
                            {'اطلاعات فروش'}
                        </Typography>
                        <Grid container spacing={2} mb={4}>
                            {formFields.map((field, index) =>
                                <Grid item xs={12} md={field.md} key={index}>
                                    <FullWidthTextField
                                        type={field.inputType}
                                        placeholder={field.placeholder}
                                        value={formData[field.valueName]}
                                        rows={field.rows}
                                        multiline={field.rows != 1}
                                        onChange={e => setFormData({ ...formData, [field.valueName]: e.target.value })} />
                                </Grid>
                            )}
                            <Grid item xs={12} md={2} lg={1.5} sx={{ my: 'auto' }}>
                                <Button
                                    variant='text'
                                    fullWidth
                                    onClick={() => window.open(`https://www.google.com/search?q=${bookData.name} ${bookData.publication}&tbm=isch`, '_blank').focus()}
                                    disabled={bookData.name.length === 0}>
                                    {'جستجوی تصویر'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent='left' alignItems='left'>
                            <Grid item xs={12} md={3}>
                                <Typography color='error'>
                                    {error}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    onClick={() => { setBookData(defaultBookData); setFormData(defaultformData); setError('') }}
                                    variant='outlined'
                                    fullWidth>
                                    {'از نو'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    onClick={onSubmitClicked}
                                    variant='contained'
                                    fullWidth>
                                    {'ثبت'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Paper>
            </Container>
        </>
    )
}

export default AddBooksPage;