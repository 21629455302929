import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function getBookFromBookStore(data, onSuccess, onFailure) {

    let filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));

    let options = {
        method: 'get',
        url: links.bookStore.getBook,
        params: filteredData,
    }
    authorizedAxios(options, onSuccess, onFailure);
}