import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function getSummary(onSuccess, onFailure) {

    let options = {
        method: 'get',
        url: links.bookStore.getSummary,
    }
    authorizedAxios(options, onSuccess, onFailure);
}