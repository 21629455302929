import * as React from 'react'

function NotFoundPage() {
    return (
        <div>
            <h3 style={{color: 'red'}}>The page you are looking for is not found</h3>
        </div>
    )
}

export default NotFoundPage;