import * as React from 'react'
import { Grid, Stack, Typography } from '@mui/material';
import FullWidthTextField from './FullWidthTextField'

function FormInput({ label, value, setValue, xs, md, rows = 1, disabled = false, type = 'text' }) {
    return (
        <Grid item xs={xs} md={md}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='h6'>
                    {label}
                </Typography>
                <FullWidthTextField
                    placeholder={label}
                    type={type}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    multiline={rows > 1}
                    rows={rows}
                />
            </Stack>
        </Grid>
    )
}

export default FormInput;