import * as React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, Route, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AuthAtom from '../atoms/AuthAtom'
import LoadingPage from '../pages/LoadingPage';

function ProtectedRoute({strict=true}) {
    const [auth, setAuth] = useRecoilState(AuthAtom);
    const [authCookie] = useCookies(['auth'])
    const navigate = useNavigate()

    React.useEffect(() => {
        if (authCookie && authCookie.data) {
            setAuth(authCookie.data)
        }else {
            navigate('/login')
        }
    }, [])

    if (auth || !strict) {
      return <Outlet />
    }

    return (
        <LoadingPage />
    )
  }

  export default ProtectedRoute