import * as React from 'react'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { Dialog, DialogContent, Stack, Typography } from '@mui/material'

function BarcodeDialog({ onResult, onClose, open, error }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <DialogContent>
                <Stack alignItems={'center'} direction={'column'}>
                    <BarcodeScannerComponent
                        width='100%'
                        onUpdate={(err, result) => {
                            if (result)
                                onResult(result)
                        }}
                    />
                    <Typography variant='h6' my={2}>
                        {'بارکد کتاب را جلوی دوربین نگه دارید'}
                    </Typography>
                    <Typography variant='subtitle1' color='error'>
                        {error}
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default BarcodeDialog;