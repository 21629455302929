import * as React from 'react'
import { Card, CardActionArea, CardContent, CardHeader, Typography, Stack } from '@mui/material';
import colors from '../../Theme/colors';
import translate from '../../utils/translate';

function TicketMessage({ text, type, created_at }) {

    let color = type === 'question' ? '#ffffff' : `${colors.primary[3]}22`

    return (
        <Card variant={'outlined'} sx={{ mb: 2, background: color }}>
            <CardHeader
                title={
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={4}>
                        <Typography variant='h6'>
                            {translate(type)}
                        </Typography>
                        <Typography>
                            {new Date(created_at).toLocaleDateString('fa-IR')}
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Typography sx={{whiteSpace: 'pre-line'}}>
                    {text}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default TicketMessage;