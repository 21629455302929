import { IconButton, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as React from 'react'

const columns = [
    {
        field: 'totalPrice',
        headerName: 'قیمت کل (تومان)',
        width: 160,
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) =>
            params.row.price * params.row.count
    },
    {
        field: 'price',
        headerName: 'قیمت واحد',
        type: 'number',
        width: 110,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'count',
        headerName: 'تعداد',
        width: 150,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'name',
        headerName: 'نام کتاب',
        width: 150,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'id',
        headerName: 'ردیف',
        width: 90,
        align: 'right',
        headerAlign: 'right',
    },
];

function SaleItemsTable({ data, editable, onIncrease, onDecrease, sx }) {

    const actionsColumn = {
        field: 'actions',
        headerName: 'عملیات',
        width: 110,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => (
            <Stack direction={'row'} >
                <IconButton onClick={() => onDecrease(params.row.book_id)}>
                    <RemoveIcon />
                </IconButton>
                <IconButton onClick={() => onIncrease(params.row.book_id)}>
                    <AddIcon />
                </IconButton>
            </Stack>
        ),
    }

    return (
        <DataGrid
            sx={{ ...sx, padding: 2, direction: 'ltr' }}
            rows={data}
            columns={editable ? [actionsColumn, ...columns] : columns}
            pageSize={10}
            autoHeight
            disableSelectionOnClick
            hideFooterPagination
        />
    )
}

export default SaleItemsTable;