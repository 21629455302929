import colors from './colors'
const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: colors.primary[5]
        },
        secondary: {
            main: colors.secondary[11],
        },
        success: {
            main: '#4caf50',
        },
        text: {
            primary: '#3d4049',
        },
        warning: {
            main: '#ff9800',
        },
    },
    typography: {
        fontFamily: 'Noto Naskh Arabic',
    },
    shape: {
        borderRadius: 24,
    },
    direction: 'rtl',
    spacing: 8,
};
export default themeOptions