import * as React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import FormInput from '../FormInput';
import { LoadingButton } from '@mui/lab'

function BookStoreInfoForm({
    paperProps,
    onSubmit,
    submitLabel,
    loading,
    data,
    setDataKey,
    hiddenItems = [],
}) {

    return (
        <Paper sx={{ p: 4, mb: 4 }} {...paperProps}>
            <Typography variant='h4' mb={4}>
                {'اطلاعات کتاب‌فروشی'}
            </Typography>
            <Grid container spacing={2} mb={2}>
                {!hiddenItems.includes('username') &&
                    <FormInput
                        label={'نام کاربری'}
                        value={data.username}
                        disabled
                        setValue={(v) => setDataKey('username', v)}
                        xs={12}
                        md={4} />
                }
                <FormInput
                    label={'نام کتاب‌فروشی'}
                    value={data.name}
                    setValue={(v) => setDataKey('name', v)}
                    xs={12}
                    md={4} />
            </Grid>
            <Grid container spacing={2} mb={2}>
                <FormInput
                    label={'آدرس'}
                    value={data.address}
                    setValue={(v) => setDataKey('address', v)}
                    rows={3}
                    xs={12}
                    md={8} />
            </Grid>
            <Grid container spacing={2} mb={4}>
                <FormInput
                    label={'شماره تماس'}
                    type={'number'}
                    value={data.phone_number}
                    setValue={(v) => setDataKey('phone_number', v)}
                    xs={12}
                    md={4} />
            </Grid>
            {!hiddenItems.includes('submit') &&
                <Grid container justifyContent={'left'}>
                    <Grid item xs={12} md={2} lg={1.5}>
                        <LoadingButton
                            fullWidth
                            onClick={onSubmit}
                            loading={loading}
                            variant='contained'
                        >
                            {submitLabel}
                        </LoadingButton>
                    </Grid>
                </Grid>
            }
        </Paper>
    )
}

export default BookStoreInfoForm;