import AuthAtom from '../atoms/AuthAtom'
import { getRecoil } from 'recoil-nexus'

const axios = require('axios');
const authorizedAxios = (options, onSuccess, onFailure) => {

    let user = getRecoil(AuthAtom)

    if (options.headers)
        options.headers.Authorization = "Bearer " + user.token;
    else
        options.headers = { Authorization: "Bearer " + user.token }
    axios(options).then((res) => {
        onSuccess(res.data)
    }, (err) => {
        let status = err.response.status
        if (status === 401 || status === 403)
            window.location.href = "https://booklet-app.ir/login"
        else
            onFailure(err.response)
    })
}

export default authorizedAxios;