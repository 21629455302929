import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function closeTicket(id, onSuccess, onFailure) {

    let options = {
        method: 'post',
        url: links.ticket.close + "/" + id,
    }
    authorizedAxios(options, onSuccess, onFailure);
}