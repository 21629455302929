import * as React from 'react'
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import themeOptions from './Theme/options'
import LandingPage from './pages/LandingPage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { CookiesProvider } from 'react-cookie';
import authRoutes from './routes/authRoutes';
import dashboardRoutes from './routes/dashboardRoutes';
import bookStoreRoutes from './routes/bookStoreRoutes';
import ticketsRoutes from './routes/ticketsRoutes';
import ProtectedRoute from './routes/ProtectedRoute';
import NotFoundPage from './pages/NotFoundPage'
import HomePage from './pages/home/HomePage'
import RecoilNexus from "recoil-nexus";
import TestPage from './pages/test/TestPage'


const theme = createTheme(themeOptions)

function App() {
  return (
    <div dir='rtl'>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <RecoilRoot>
            <RecoilNexus />
            <Router>
              <Routes>
                <Route path='/test' element={<TestPage />} />
                <Route path='/' index element={<LandingPage />} />
                {authRoutes.map((route, index) =>
                  <Route key={index} path={route.path} element={route.element} />
                )}

                <Route path='/' element={<ProtectedRoute />}>
                  <Route path='/' element={<HomePage />}>
                    {Object.values(dashboardRoutes).map((route, index) =>
                      <Route key={index} path={route.path} element={route.element} />
                    )}
                    {Object.values(ticketsRoutes).map((route, index) => 
                      <Route key={index} path={route.path} element={route.element} />
                    )}
                  </Route>
                </Route>
                <Route path='/bookstore/:bookStoreUsername' element={<ProtectedRoute strict={false} />}>
                  {Object.values(bookStoreRoutes).map((route, index) =>
                    <Route key={index} path={route.path} element={route.element} />
                  )}
                </Route>
                <Route path='*' element={<NotFoundPage />} />
              </Routes>
            </Router>
          </RecoilRoot>
        </CookiesProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
