import { Avatar, Badge, IconButton, Card, Stack, Typography, Button } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import colors from '../../Theme/colors'
import logout from '../../axios/auth/logout'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

function ProfileAvatar({ src, alt, name, address, onAddPictureClick }) {

    const [authCookie, setAuthCookie, removeCookie] = useCookies(['auth'])
    const navigate = useNavigate()

    const logoutClicked = () => {
        logout(() => {
            removeCookie('data', null)
            navigate('/login')
        })
    }
    return (
        <Stack p={2} justifyContent='center' alignItems='center'>
            <div>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    badgeContent={
                        <Card variant='elevation' >
                            <IconButton size="small">
                                <AddAPhotoIcon />
                            </IconButton>
                        </Card>
                    }
                >
                    <Avatar alt={alt} src={src} sx={{ width: 100, height: 100 }} />
                </Badge>
            </div>
            <Typography variant='h6' mt={3}>
                {name}
            </Typography>
            <Typography
                variant='subtitle'
                color={colors.grey}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {address}
            </Typography>
            <Button
                sx={{ mt: 2 }}
                variant="text"
                fullWidth
                onClick={logoutClicked}
            >
                {'خروج'}
            </Button>
        </Stack>
    )
}

export default ProfileAvatar;