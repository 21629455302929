import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import * as React from 'react'
import SaleItemsTable from './SaleItemsTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Sale({ id, created_at, books, sx }) {

    let totalPrice = 0
    books.forEach(book => {
        totalPrice += book.price * book.count;
    });

    return (
        <Accordion
            sx={{...sx}}
            variant={'outlined'}
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography ml={4} variant='h6'>
                    {`${id}.`}
                </Typography>
                <Typography ml={8} variant='h6'>
                    {new Date(created_at).toLocaleDateString('fa-IR')}
                </Typography>
                <Typography color={'text.secondary'} alignItems='center'>
                    {`قیمت کل: ${totalPrice} تومان`}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <SaleItemsTable data={books} />
            </AccordionDetails>
        </Accordion>
    )
}

export default Sale;