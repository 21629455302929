import * as React from 'react'
import { useRecoilState } from 'recoil'
import EnvironmentAtom from '../../atoms/EnvironmentAtom'
import translate from '../../utils/translate'
import { Container, Typography, Stack, IconButton, Button, Grid, Paper, TextField, OutlinedInput, InputAdornment, CircularProgress, Divider } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Barcodedialog from '../../compoments/Books/BarcodeDialog'
import useAudio from '../../compoments/Utils/Audio'
import ScannerBeep from '../../resources/scanner-beep.mp3'
import SaleItemsTable from '../../compoments/Sales/SaleItemsTable'
import getBookFromBookStore from '../../axios/books/getBookFromBookStore'
import IsbnInput from '../../compoments/Books/IsbnInput'
import FullWidthTextField from '../../compoments/Forms/FullWidthTextField'
import LoadingButton from '@mui/lab/LoadingButton';
import createInvoice from "../../axios/invoices/createInvoice"

function AddSalePage() {

    const [error, setError] = React.useState('')
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [scannerBeep, toggleSound] = useAudio(ScannerBeep)
    const [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'addSale', appbarTitle: translate('addSale') })
    }, [])

    const [invoiceData, setInvoiceData] = React.useState([])
    const [customerData, setCustomerData] = React.useState({
        phone_number: '',
    })
    const [dialogState, setDialogState] = React.useState({
        open: true,
        result: '',
        loading: false,
        error: '',
    })

    const addBookToInvoice = (book_data) => {
        let found = false
        let newInvoice = invoiceData.map((item) => {
            if (item.book_id === book_data.book_id) {
                found = true;
                return { ...item, count: item.count + 1 }
            }
            return item
        })
        if (!found)
            newInvoice.push({
                id: invoiceData.length + 1,
                book_id: book_data.book_id,
                count: 1,
                name: book_data.name,
                price: book_data.price,
            })
        setInvoiceData(newInvoice)
    }

    const increaseBookCount = (book_id) => {
        //TODO: check for maximum
        setInvoiceData(invoiceData.map(item => {
            if (item.book_id === book_id)
                return { ...item, count: item.count + 1 }
            return item;
        }))
    }

    const decreaseBookCount = (book_id) => {
        let newInvoiceData = invoiceData.map(item => {
            if (item.book_id === book_id)
                return { ...item, count: item.count - 1 }
            return item;
        })
        newInvoiceData = newInvoiceData.filter(item => item.count > 0)
        setInvoiceData(newInvoiceData)
    }

    const barcodeDetected = (result) => {
        if (result.text || dialogState.result)
            setDialogState({
                open: false,
                loading: true,
                error: '',
                result: result && result.text ? result.text : dialogState.result
            })
    }

    React.useEffect(() => {
        if (!dialogState.open && dialogState.loading && dialogState.result) {
            console.log("Sending...")
            getBookFromBookStore({
                isbn: dialogState.result
            }, (res) => {
                addBookToInvoice(res.data.book)
                toggleSound()
                setTimeout(() => setDialogState({ ...dialogState, open: true, error: '', loading: false, }), 500)
            }, (err) => {
                setTimeout(() => setDialogState({ ...dialogState, open: true, error: err.data.message, loading: false }), 500)
            })
        }
    }, [dialogState.loading])

    const checkData = () => {
        return invoiceData.length > 0 && customerData.phone_number
    }

    const submitClicked = () => {
        if (checkData()) {
            setIsSubmitting(true)
            createInvoice({
                books: invoiceData,
                customer_phone_number: customerData.phone_number
            }, (res) => {
                setInvoiceData([])
                setCustomerData({phone_number: ''})
                setIsSubmitting(false)
            }, (err) => {
                setError(err.data.message)
                setIsSubmitting(false)
            })
        }
    }

    return (
        <>
            <Barcodedialog
                error={dialogState.error}
                open={dialogState.open}
                onResult={barcodeDetected}
                onClose={() => setDialogState({ result: '', open: false })} />
            <Container disableGutters>
                <Paper sx={{ p: 4 }}>
                    <Stack direction={'column'}>
                        <Typography variant='h6' mb={4}>
                            {'فاکتور فروش'}
                        </Typography>
                        <Grid container spacing={2} mb={4} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <IsbnInput
                                    value={dialogState.result}
                                    loading={dialogState.loading}
                                    openDialog={() => setDialogState({ ...dialogState, open: true })}
                                    setValue={(v) => setDialogState({ ...dialogState, result: v })} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    onClick={barcodeDetected}
                                    variant='outlined'
                                    fullWidth
                                    endIcon={<AddIcon />}
                                    dir='ltr'>
                                    {'افزودن کتاب'}
                                </Button>
                            </Grid>
                        </Grid>
                        <SaleItemsTable
                            data={invoiceData}
                            editable
                            onIncrease={increaseBookCount}
                            onDecrease={decreaseBookCount} />
                        <Divider sx={{ my: 4 }} />
                        <Typography variant='h6' mb={4}>
                            {'اطلاعات خریدار'}
                        </Typography>
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={12} md={4}>
                                <FullWidthTextField
                                    type='number'
                                    value={customerData.phone_number}
                                    onChange={(e) => setCustomerData({ ...customerData, phone_number: e.target.value })}
                                    placeholder='شماره تماس' />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'flex-end'} spacing={4}>
                            <Grid item xs={12} md={3} sm={6}>
                                <Typography color='error' textAlign={'left'}>
                                    {error}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sm={6} lg={2}>
                                <LoadingButton
                                    variant='contained'
                                    onClick={() => submitClicked()}
                                    fullWidth loading={isSubmitting}>
                                    {'تکمیل خرید'}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </Paper>
            </Container>
        </>
    )
}

export default AddSalePage;