import DashboardPage from "../pages/home/DashboardPage"
import SalesPage from "../pages/home/SalesPage"
import BooksPage from "../pages/home/BooksPage"
import AddBookPage from "../pages/home/AddBookPage"
import OrdersPage from "../pages/home/OrdersPage"
import SettingsPage from "../pages/home/SettingsPage"
import TicketsPage from "../pages/home/TicketsPage"
import AddSalePage from "../pages/home/AddSalePage"
import AddTicketPage from "../pages/home/AddTicketPage"

const dashboardRoutes = {
    dashboard: {path: '/dashboard', element: <DashboardPage />},
    books: {path: '/books', element: <BooksPage />},
    addBook: {path: '/add-book', element: <AddBookPage />},
    salesHistory: {path: '/sales-history', element: <SalesPage />},
    addSale: {path: '/add-sale', element: <AddSalePage />},
    orders: {path: '/orders', element: <OrdersPage />},
    settings: {path: '/settings', element: <SettingsPage />},
    tickets: {path: '/tickets', element: <TicketsPage />},
    addTicket: {path: '/add-ticket', element: <AddTicketPage />},
}

export default dashboardRoutes;