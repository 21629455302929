import * as React from 'react'
import { CircularProgress, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
function SearchBox({ onSearch, loading = false }) {
    const [value, setValue] = React.useState('')
    return (
        <OutlinedInput
            fullWidth
            placeholder='جستجو'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            endAdornment={
                <InputAdornment position="end">
                    {loading ? <CircularProgress /> :
                        <IconButton
                            aria-label="جستجو"
                            onClick={() => onSearch(value)}
                            edge="end"
                        >
                            <SearchIcon />
                        </IconButton>}
                </InputAdornment>
            } />
    )
}

export default SearchBox;