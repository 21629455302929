import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function getTicketMessages(ticketId, onSuccess, onFailure) {

    let options = {
        method: 'get',
        url: links.ticket.ticket + `/${ticketId}`,
    }
    authorizedAxios(options, onSuccess, onFailure);
}