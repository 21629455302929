import * as React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import FormInput from '../FormInput';
import { LoadingButton } from '@mui/lab'

function UserInfoForm({
    paperProps,
    onSubmit,
    submitLabel,
    loading,
    data,
    setDataKey,
    hiddenItems = [],
}) {


    return (
        <Paper sx={{ p: 4, mb: 4 }} {...paperProps}>
            <Typography variant='h4' mb={4}>
                {'اطلاعات کاربری'}
            </Typography>
            <Grid container spacing={2} mb={2}>
                {!hiddenItems.includes('username') &&
                    <FormInput
                        label={'نام کاربری'}
                        value={data.username}
                        setValue={(v) => setDataKey('username', v)}
                        disabled={data.username !== ''}
                        xs={12}
                        md={4} />
                }
            </Grid>
            <Grid container spacing={2} mb={2}>
                <FormInput
                    label={'نام'}
                    value={data.name}
                    setValue={(v) => setDataKey('name', v)}
                    xs={12}
                    md={4} />
                <FormInput
                    label={'نام خانوادگی'}
                    value={data.surname}
                    setValue={(v) => setDataKey('surname', v)}
                    xs={12}
                    md={4} />
            </Grid>
            <Grid container spacing={2} mb={4}>
                {!hiddenItems.includes('phone_number') &&
                    <FormInput
                        label={'شماره تماس'}
                        value={data.phone_number}
                        setValue={(v) => setDataKey('phone_number', v)}
                        disabled={data.phone_number !== ''}
                        type={'number'}
                        xs={12}
                        md={4} />
                }
                <FormInput
                    label={'آدرس ایمیل'}
                    value={data.email}
                    type={'email'}
                    setValue={(v) => setDataKey('email', v)}
                    xs={12}
                    md={4} />
            </Grid>
            {!hiddenItems.includes('submit') && 
                <Grid container justifyContent={'left'}>
                    <Grid item xs={12} md={2} lg={1.5}>
                        <LoadingButton
                            fullWidth
                            onClick={onSubmit}
                            loading={loading}
                            variant='contained'
                        >
                            {submitLabel}
                        </LoadingButton>
                    </Grid>
                </Grid>
            }
        </Paper>
    )
}

export default UserInfoForm;