import * as React from 'react'
import NoImage from '../../resources/No-Image.jpg'
import { Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, IconButton, OutlinedInput, Paper, Stack, TextField, Typography } from '@mui/material'
import colors from '../../Theme/colors'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import changeBookCount from '../../axios/bookstore/changeBookCount'

function Book({ book_id, name, writer, publication, price, count, image, isBookStore = false, sx }) {
    const [status, setStatus] = React.useState('') //'', loading, editing
    const [countState, setCountState] = React.useState(count)
    const [countState2, setCountState2] = React.useState(count)

    const imageData = image ? `data:image/png;base64,${image}` : NoImage

    React.useEffect(() => {
        if (count !== countState && status !== 'editing')
            setTimeout(() => {
                setCountState2(countState)
            }, 1500)
    }, [countState, setCountState])

    React.useEffect(() => {
        if (countState === countState2 && countState !== count) {
            setStatus('loading')
            sendChangeCountRequest()
        }
    }, [countState2, setCountState2])

    const sendChangeCountRequest = () => {
        changeBookCount({
            book_id: book_id,
            count: countState2
        }, (res) => { setStatus('') }, () => { })
    }

    const countChaged = (action) => {
        if (action === 'add')
            setCountState(countState + 1)
        else
            setCountState(Math.max(countState - 1, 0))
    }

    const countEdited = (e) => {
        if (e.key === 'Enter') {
            setStatus('')
            setCountState2(e.target.value)
        } else if (e.key === 'Escape') {
            setCountState(countState2)
            setStatus('')            
        }
    }

    return (
        <Card sx={{ ...sx }} variant={'outlined'}>
            <CardActionArea>
                <CardMedia
                    component='img'
                    src={imageData}
                    alt={name}
                    height={260}
                />
                <CardContent sx={{ p: 2 }}>
                    <Typography
                        variant='h6'
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            lineClamp: 1,
                            width: '90%',
                            mb: 2,
                        }}>
                        {name}
                    </Typography>
                    <Typography
                        variant='body2'
                        color={colors.grey}
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            lineClamp: 1,
                            mb: 2,
                        }}>
                        {writer}<br />{publication}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{ p: 2 }}>
                <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'}>
                    <Paper variant='outlined'>
                        <Stack direction={'row'} alignItems={'center'}>
                            <IconButton disabled={status !== ''} size='small' color={'error'} onClick={() => countChaged('remove')}>
                                <RemoveIcon />
                            </IconButton>
                            {status === 'loading'
                                ? <CircularProgress size={24} />
                                : status === 'editing'
                                    ? <OutlinedInput
                                        size='small'
                                        sx={{height: '32px', width: '48px'}}
                                        value={countState}
                                        autoFocus
                                        onChange={(e) => setCountState(e.target.value)}
                                        onKeyDown={countEdited}
                                        onBlur={() => {setStatus(''); setCountState(countState2)}}
                                    />
                                    : <Typography
                                        onClick={() => setStatus('editing')}
                                        textAlign={'center'}
                                        sx={{ px: 1, pt: 0.7, cursor: 'pointer' }}>
                                        {countState}
                                    </Typography>
                            }
                            <IconButton disabled={status !== ''} size='small' color={'success'} onClick={() => countChaged('add')}>
                                <AddIcon />
                            </IconButton>
                        </Stack>
                    </Paper>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} sx={{ width: '100%' }}>
                        <Typography variant='h6' sx={{ textAlign: 'left', ml: 0.5 }}>
                            {price}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {'تومان'}
                        </Typography>
                    </Stack>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default Book;