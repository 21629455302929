import * as React from 'react'
import { Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import translate from '../../utils/translate';
import { useNavigate } from 'react-router-dom'
import closeTicket from '../../axios/tickets/closeTicket'

function Ticket({ id, title, status, updated_at, onClosed }) {

    let statusColor = status == 'waiting response' ? '#0288d1'
        : status == 'answered' ? '#2e7d32' : '#d32f2f'

    const navigate = useNavigate()

    const viewClicked = () => {
        let url = "/ticket/" + id
        navigate(url)
    }

    const closeClicked = () => {
        closeTicket(id, onClosed)
    }

    return (
        <Card variant={'outlined'} sx={{ p: 1, mb: 2 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={1}>
                            <Typography variant={'overline'}>
                                {'عنوان'}
                            </Typography>
                            <div>
                                <Typography mr={1}>
                                    {title}
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <Divider orientation='vertical' sx={{ display: { xs: 'none', md: 'block' } }} />
                        <Divider orientation='horizontal' sx={{ display: { xs: 'block', md: 'none' } }} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Stack spacing={1}>
                            <Typography variant={'overline'}>
                                {'وضعیت'}
                            </Typography>
                            <div>
                                <Typography mr={1} color={statusColor}>
                                    {translate(status)}
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <Divider orientation='vertical' sx={{ display: { xs: 'none', md: 'block' } }} />
                        <Divider orientation='horizontal' sx={{ display: { xs: 'block', md: 'none' } }} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant={'overline'}>
                                {'آخرین بروزرسانی'}
                            </Typography>
                            <div>
                                <Typography mr={1}>
                                    {new Date(updated_at).toLocaleDateString('fa-IR')}
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={'auto'}>
                        <Divider orientation='vertical' sx={{ display: { xs: 'none', md: 'block' } }} />
                        <Divider orientation='horizontal' sx={{ display: { xs: 'block', md: 'none' } }} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button variant='outlined' fullWidth onClick={viewClicked} sx={{ mb: 1 }}>
                            {'مشاهده'}
                        </Button>
                        <Button variant='outlined' color={'error'} fullWidth onClick={closeClicked}>
                            {'بستن تیکت'}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Ticket;