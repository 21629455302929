import * as React from 'react'
import { Button, Container, Box, Paper, InputBase, Typography } from '@mui/material';
import LandingHeader from '../resources/landing-header.jpg'
import Mask from '../utils/Mask'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import colors from '../Theme/colors'
import TopAppBar from '../compoments/AppBar';
import styled from 'styled-components';
import { unstable_styleFunctionSx } from '@mui/system';
import Overlay from '../resources/overlay.svg'
import { useNavigate } from 'react-router-dom';

const Div = styled('div')(unstable_styleFunctionSx);

function LandingPage() {

    const navigate = useNavigate();

    return (
        <Box sx={{ position: 'relative', backgroundColor: colors.secondary[1] }}>
            <TopAppBar />
            <Div sx={{ display: { xs: 'none', lg: 'block' } }} style={{ maskImage: `url(${Overlay})`, WebkitMaskImage: `url(${Overlay})`, WebkitMaskRepeat: 'no-repeat' }}>
                <Mask shade='0.2' r='252' g='252' b='247'>
                    <img src={LandingHeader} style={{ width: '100%' }} />
                </Mask>
            </Div>
            <Div sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Mask shade='0.2' r='252' g='252' b='247' >
                    <img src={LandingHeader} style={{ width: '450%' }} />
                </Mask>
            </Div>
            <Container 
                sx={{ 
                    position: 'absolute', 
                    p: 4,
                    m: 4,
                    top: '20%', 
                    right: { md: '0%',lg: '12%' },
                    background: {xs: `${colors.secondary[1]}af`, lg: 'transparent'},
                    borderRadius: '24px',
                }}>
                <Typography variant='subtitle1' color='grey'>
                    {'وب اپلیکیشن بوک‌لت'}
                </Typography>
                <Typography variant='h2' sx={{ my: 8, fontSize: {xs: 40, lg: 60}}}>
                    {'کتاب‌فروشی آنلاین شهر شما'}
                </Typography>
                <Button     
                    color='primary' 
                    variant='contained' 
                    size='medium'
                    onClick={() => navigate('/register')}
                    sx={{ py: 2, px: 4, borderRadius: 36, fontSize: 20 }} 
                    endIcon={<ArrowBackIosIcon />}>
                    {'ثبت نام ‌ ‌ ‌'}
                </Button>
            </Container>
        </Box>
    )
}

export default LandingPage