import links from "../../data/links";
import axios from "axios";

export default function getBooks(data, onSuccess, onFailure) {

    let options = {
        method: 'get',
        url: links.bookStore.getBooks,
        params: data
    }
    axios(options)
        .then((res) => {
            onSuccess(res.data)
        }, (err) => {
            onFailure(err.response)
        });
}