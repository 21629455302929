import { atom } from 'recoil';

const AuthAtom = atom({
    key: 'AuthAtom',
    default: null,
});

/*
{
        id: 1,
        username: "taha7900",
        name: "M.Taha",
        surname: "Jahani Nezhad",
        phone_number: "09367642209",
        email: "taha7900@gmail.com",
        phone_number_verified_at: "2022-03-18 15:54:52",
        email_verified_at: "2022-03-18T15:54:52.000000Z",
        created_at: "2022-03-18T15:54:52.000000Z",
        updated_at: "2022-03-18T15:54:52.000000Z",
        roles: [
            {
                "id": 1,
                "user_id": 1,
                "role": "admin"
            }
        ],
        book_stores: [
            {
                username: "javan-kashan",
            },
        ],
        token: "1|hYpPoFPKUmcg70SMaOShb6gwpkL6TBrIQP8SBQUu"
    }
*/

export default AuthAtom;