import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function addMEssage(data, onSuccess, onFailure) {

    let options = {
        method: 'post',
        url: links.ticket.addMessage,
        data: data,
    }
    authorizedAxios(options, onSuccess, onFailure);
}