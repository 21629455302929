import * as React from 'react'
import { useParams } from 'react-router-dom'
import NoImage from '../../resources/No-Image.jpg'

function BookDetailPage() {
    const { bookStoreUsername, id } = useParams()
    React.useEffect(() => {
        //get book data by id
    }, [])
    return (
        <div>
            <h5>Showing detail for book {id} from {bookStoreUsername}</h5>
        </div>
    )
}

export default BookDetailPage