import * as React from 'react'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components';
import { unstable_styleFunctionSx } from '@mui/system';


const useStyles = makeStyles({
    container: {
        position: 'relative',
    },
    mask: {
        float: 'left',
        position: 'absolute',
        left: '0px',
        top: '0px',
        zIndex: '0',
        backgroundColor: props => `rgba(${props.r}, ${props.g}, ${props.b}, ${props.shade})`,
        width: '100%',
        height: '100%',
    }
})

function Mask({ children, shade = 0.7, r = '0', g = '0', b = '0' }) {

    let classes = useStyles({ shade, r, g, b })

    return (
        <div className={classes.container}>
            {children}
            <div className={classes.mask}></div>
        </div>
    )
}

export default Mask