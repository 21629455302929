import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import Book from '../../compoments/Books/Book';
import translate from '../../utils/translate';
import { Container, Grid, Pagination, Paper, Stack } from '@mui/material'
import getBooks from '../../axios/bookstore/getBooks'
import AuthAtom from '../../atoms/AuthAtom'
import SearchBox from '../../compoments/Forms/SearchBox';
import { useSearchParams } from 'react-router-dom'

function BooksPage() {

    let [user] = useRecoilState(AuthAtom)
    const [searchParams, setSearchParams] = useSearchParams()
    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({ ...env, sidebarSelectedItem: 'books', appbarTitle: translate('books') })
    }, [])

    const [books, setBooks] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const [searchValue, setSearchValue] = React.useState('')

    React.useEffect(() => {
        fetchPage(1, '')
    }, [])

    const fetchPage = (number, value) => {
        setSearchParams({ page: number })
        getBooks({
            store_username: user.book_stores[0].username,
            search: value,
            page: number
        },
            (res) => {
                setBooks(res.data.books)
                setLoading(false)
                setPageCount(res.data.last_page)
            },
            (e) => { console.log(e) })
    }

    const filterBooks = (value) => {
        setLoading(true)
        setSearchValue(value)
        fetchPage(1, value)
    }

    return (
        <Container disableGutters>
            <Paper sx={{ p: 4 }} >
                <Grid container sx={{ mb: 4 }}>
                    <Grid item xs={12} md={6} lg={4}>
                        <SearchBox loading={loading} onSearch={filterBooks} />
                    </Grid>
                </Grid>
                <div>
                    <Grid container spacing={3}>
                        {books.map((book, index) =>
                            <Grid item xs={12} md={3} key={index}>
                                <Book {...book} />
                            </Grid>
                        )}
                    </Grid>
                </div>
                <Grid container sx={{ mt: 4 }} alignItems={'center'} justifyContent={'center'}>
                    <Grid item justifyContent={'center'} alignItems={'center'}>
                        {pageCount > 1 && <Pagination
                            count={pageCount}
                            page={parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1}
                            onChange={(e, v) => fetchPage(v, searchValue)} />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default BooksPage;