import authorizedAxios from "../authorizedAxios";
import links from "../../data/links";

export default function getAllInvoices(data, onSuccess, onFailure) {

    let filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));

    let options = {
        method: 'get',
        url: links.invoice.getAll,
        params: filteredData
    }
    authorizedAxios(options, onSuccess, onFailure);
}