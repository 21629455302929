import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';
import { Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import FullWidthTextField from '../../compoments/Forms/FullWidthTextField'
import createTicket from "../../axios/tickets/createTicket"
import {useNavigate} from 'react-router-dom'

function AddTicketPage() {

    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({...env, sidebarSelectedItem: 'addTicket', appbarTitle: translate('addTicket')})
    }, [])


    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        let data = {
            title: title,
            text: text,
        }
        createTicket(data, (res) => {
            navigate('/tickets')
        }, (err) => {
            setLoading(false)
            setError(err.data.message)
        })
    }

    return (
        <Container disableGutters>
            <Paper sx={{ p: 4 }}>
                <form onSubmit={onSubmit}>
                    <Grid container>
                        <Grid item xs={12} md={5} lg={4} mb={4}>
                            <Stack direction={'column'} spacing={2}>
                                <Typography variant={'h6'}>
                                    {'موضوع'}
                                </Typography>
                                <FullWidthTextField
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder='موضوع' />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} mb={4}>
                            <Stack direction={'column'} spacing={2}>
                                <Typography variant={'h6'}>
                                    {'پیام'}
                                </Typography>
                                <FullWidthTextField
                                    placeholder='پیام'
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    multiline
                                    rows={8}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} mb={6}>
                            <Typography color={'error'}>
                                {error}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'left'}>
                        <Grid item xs={12} md={2} lg={1.5}>
                            <LoadingButton
                                type='submit'
                                variant='contained'
                                fullWidth
                                loading={loading}
                            >
                                {'ثبت'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default AddTicketPage;