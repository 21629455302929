import * as React from 'react'
import { useRecoilState } from 'recoil';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import translate from '../../utils/translate';
import {Container, Paper} from '@mui/material'
import Ticket from '../../compoments/Tickets/Ticket';
import getAllTickets from '../../axios/tickets/getAllTickets'

function TicketsPage() {

    let [env, setEnv] = useRecoilState(EnvironmentAtom)
    React.useEffect(() => {
        setEnv({...env, sidebarSelectedItem: 'tickets', appbarTitle: translate('tickets')})
    }, [])

    const [tickets, setTickets] = React.useState([])

    const ticketClosed = (id) => {
        setTickets(tickets.map(item => {
            if (item.id === id)
                item.status = 'closed'
            return item;
        }))
    }

    React.useEffect(() => {
        getAllTickets((res) => {
            let tickets = res.data.tickets;
            setTickets(tickets)
        })
    }, [])

    return (
        <Container disableGutters>
            <Paper sx={{p: 4}}>
                {tickets.map((ticket, index) => {
                    return <Ticket {...ticket} key={index} onClosed={() => ticketClosed(ticket.id)} />
                })}
            </Paper>
        </Container>
    )
}

export default TicketsPage;