import * as React from 'react';
import { Box, Collapse, AppBar, Divider, Drawer, List, Toolbar, IconButton, Typography, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CssBaseline from '@mui/material/CssBaseline';
import { useRecoilState } from 'recoil';
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import EnvironmentAtom from '../../atoms/EnvironmentAtom';
import AuthAtom from '../../atoms/AuthAtom'
import ProfileAvatar from '../../compoments/Sidebar/ProfileAvatar';
import Logo from '../../resources/Booklet-Logo.png'
import dashboardRoutes from '../../routes/dashboardRoutes';
import SpeedIcon from '@mui/icons-material/Speed';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import BookIcon from '@mui/icons-material/Book';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ListItemWithChild from '../../compoments/Sidebar/ListItemWithChid';
import SimpleListItem from '../../compoments/Sidebar/SimpleListItem';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const drawerWidth = 240;
const appBarHeight = 56;

const items = [
    {
        key: 'dashboard',
        label: 'داشبورد',
        url: dashboardRoutes['dashboard'].path,
        icon: <SpeedIcon />,
    },
    {
        key: 'books',
        label: 'کتاب‌ها',
        icon: <BookIcon />,
        children: [
            {
                key: 'books',
                label: 'مشاهده‌ی همه',
                url: dashboardRoutes['books'].path,
                icon: <AutoStoriesIcon />,
            },
            {
                key: 'addBook',
                label: 'افزودن کتاب',
                url: dashboardRoutes['addBook'].path,
                icon: <AddCircleIcon />,
            },
        ]
    },
    {
        key: 'sales',
        label: 'اطلاعات فروش',
        icon: <StorefrontIcon />,
        children: [
            {
                key: 'sales',
                label: 'سابقه فروش',
                url: dashboardRoutes['salesHistory'].path,
                icon: <LocalMallIcon />,
            },
            {
                key: 'addSale',
                label: 'افزودن فروش',
                url: dashboardRoutes['addSale'].path,
                icon: <AddCircleIcon />,
            },
        ]
    },
    {
        key: 'orders',
        label: 'سفارشات',
        url: dashboardRoutes['orders'].path,
        icon: <NoteAltIcon />,
        disabled: true,
    },
    {
        key: 'settings',
        label: 'تنظیمات',
        url: dashboardRoutes['settings'].path,
        icon: <SettingsIcon />,
    },
    {
        key: 'tickets',
        label: 'تیکت‌ها',
        icon: <LocalActivityIcon />,
        children: [
            {
                key: 'tickets',
                label: 'مشاهده همه',
                icon: <ConfirmationNumberIcon />,
                url: dashboardRoutes['tickets'].path,
            },
            {
                key: 'add-ticket',
                label: 'افزودن تیکت',
                icon: <AddCircleIcon />,
                url: dashboardRoutes['addTicket'].path,
            }
        ]
    },
]

function HomePage(props) {

    let [env] = useRecoilState(EnvironmentAtom)
    let [auth] = useRecoilState(AuthAtom);
    const navigate = useNavigate()

    const onItemClicked = (path) => {
        setMobileOpen(false)
        navigate(path)
    }

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [openCollapses, setOpenCollapses] = React.useState([]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const toggleCollapse = (index) => {
        let newOpenCollapses = []
        if (openCollapses.includes(index))
            newOpenCollapses = openCollapses.filter(item => item != index)
        else
            newOpenCollapses = [...openCollapses, index]
        setOpenCollapses(newOpenCollapses)
    }

    const drawer = (
        <div>
            <ProfileAvatar src={Logo} alt={'profile'} name={auth.book_stores[0].name} address={auth.book_stores[0].address} />
            <Divider />
            <List>
                {items.map((item, index) => {
                    if (item.children) {
                        return (
                            <ListItemWithChild 
                                key={index}
                                item={item} 
                                onToggle={() => toggleCollapse(index)}
                                open={openCollapses.includes(index)}
                                onItemClicked={onItemClicked} />
                        )
                    } else {
                        return (
                            <SimpleListItem
                                key={index}
                                item={item}
                                onItemClicked={onItemClicked} />
                        )
                    }
                })}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar sx={{ display: { sm: 'none' }, height: appBarHeight }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setMobileOpen(true)}
                        sx={{ ml: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {env.appbarTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex' }}>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    <Collapse orientation="horizontal" in={mobileOpen}>
                        <Drawer
                            dir="rtl"
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            anchor='right'
                            onClose={handleDrawerToggle}
                            ModalProps={{ keepMounted: true }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}>
                            {drawer}
                        </Drawer>
                    </Collapse>
                    <Drawer
                        transitionDuration={0}
                        anchor='right'
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open>
                        {drawer}
                    </Drawer>
                </Box>
            </Box>
            <Box component="main" sx={{ p: 3, width: '100%', mt: { xs: `${appBarHeight}px`, sm: 0 } }}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default HomePage;